.footer {
  width: 100%;
  padding: 79px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #202020;
}

.footer__text {
  width: calc(100% - 140px);
  margin: 0 auto;
  padding-bottom: 20px;
  border-bottom: 1px solid #424242;
  font-family: 'Inter', Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.21;
  color: #8B8B8B;
  text-align: center;
}

.footer__container {
  width: calc(100% - 140px);
  margin: 20px 0 0;
  display: flex;
  justify-content: space-between;
}

.footer__copyright {
  margin: 0;
  font-family: 'Inter', Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.23;
  color: #fff;
}

.footer__list {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
  list-style: none;
}

.footer__link {
  font-family: 'Inter', Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.23;
  color: #fff;
  text-decoration: none;
  opacity: 1;
  transition: all .3s linear;
  cursor: pointer;
}

.footer__link:hover {
  opacity: .7;
}

@media screen and (min-width: 426px) and (max-width: 768px) {
  .footer__text {
    width: calc(100% - 60px);
  }

  .footer__container {
    width: calc(100% - 60px);
  }
}

@media screen and (max-width: 425px) {
  .footer {
    padding-top: 78px;
  }

  .footer__text {
    width: calc(100% - 20px);
    padding-bottom: 21px;
    font-size: 12px;
    line-height: 1.25;
  }

  .footer__container {
    width: calc(100% - 20px);
    margin-top: 30px;
    flex-direction: column-reverse;
    align-items: center;
  }

  .footer__copyright {
    margin-top: 30px;
    font-size: 12px;
    line-height: 1.25;
    color: #8B8B8B;
  }

  .footer__list {
    gap: 12px;
    flex-direction: column;
    align-items: center;
  }

  .footer__link {
    font-size: 12px;
    line-height: 1.25;
  }
}