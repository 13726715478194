.promo {
  width: 100%;
  background-color: #202020;
}

.promo__container {
  width: calc(100% - 80px);
  margin: 20px auto 0;
  background-color: #272727;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}

.promo__title {
  margin: 160px auto 151px auto;
  max-width: 730px;
  font-family: 'Inter', Arial, sans-serif;
  font-size: 50px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.16;
  color: #fff;
  text-align: center;
  letter-spacing: -0.04em;
}

@media screen and (min-width: 426px) and (max-width: 768px) {
  .promo__container {
    width: calc(100% - 40px);
    margin: 20px auto 0;
  }
  .promo__title {
    font-size: 40px;
    line-height: 1.3;
    margin: 350px auto 314px;
    max-width: 680px;
  }
}

@media screen and (max-width: 425px) {
  .promo__container {
    width: calc(100% - 28px);
    margin: 14px auto 0;
  }
  .promo__title {
    margin: 220px 0 212px 0;
    font-size: 29px;
    line-height: 1.276;
    max-width: 292px;
  }
}
