.filter {
  display: flex;
  width: 167px;
  justify-content: center;
  align-items: center;
  margin: 0 30px 0 29px;
}

.filter__container {
  position: relative;
  width: 36px;
  height: 20px;
  margin-right: 15px;
}

.filter__checkbox{
  display: none;
}

.filter__checkbox-label {
	cursor: pointer;
	width: 36px;
  height: 20px;
	background: #343434;
	display: block;
	border-radius: 10px;
	position: relative;
}

.filter__checkbox-label:after {
	content: '';
	position: absolute;
	top: 2px;
	left: 2px;
	width: 14px;
	height: 14px;
	background: #A0A0A0;
  border: 1px solid #fff;
	border-radius: 50%;
	transition: .3s;
}

.filter__checkbox:checked + .filter__checkbox-label:after {
	left: calc(100% - 2px);
	transform: translateX(-100%);
  background: #2BE080;
}

.filter__label {
  font-family: 'Inter', Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.38;
}

@media screen and (min-width: 426px) and (max-width: 768px) {
  .filter {
    width: 168px;
    margin: 0 20px 0 19px;
  }
}

@media screen and (max-width: 425px) {
  .filter {
    width: calc(100% - 8px);
    margin: 45px auto 0;
    padding-bottom: 44px;
    border-bottom: 1px solid #424242;
  }

  .filter__container {
    margin-right: 13px;
  }

  .filter__label {
    font-size: 11px;
    line-height: 1.64;
  }
}