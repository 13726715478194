.App {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  max-width: 1280px;
  min-height: 100vh;
  font-family: 'Inter', Arial, sans-serif;
  margin: 0 auto;
  padding: 0;
  background-color: #202020;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}