.portfolio {
  width: calc(100% - 140px);
  margin: 100px auto 0;
  display: flex;
  flex-direction: column;
}

.portfolio__title {
  font-family: 'Inter', Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.11;
  color: #8B8B8B;
  margin: 0;
}

.portfolio__links {
  width: 100%;
  margin: 50px auto 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style-type: none;
}

.portfolio__links-element {
  border-bottom: 1px solid #424242;
  padding: 20px 0 19px;
}

.portfolio__links-element:first-of-type {
  padding: 0 0 19px;
}

.portfolio__links-element:last-of-type {
  border-bottom: none;
  padding: 20px 0 0;
}

.portfolio__link {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: 'Inter', Arial, sans-serif;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.667;
  letter-spacing: -0.04em;
  color: #fff;
  margin: 0;
  text-decoration: none;
  align-items: center;
  opacity: 1;
  transition: all .3s linear;
  gap: 10px;
}

.portfolio__link:hover {
  opacity: .7;
}

.portfolio__link-img {
  width: 17px;
  height: 17px;
  margin: 4px 6px 0 0;
  letter-spacing: -0.04em;
}

@media screen and (min-width: 426px) and (max-width: 768px) {
  .portfolio {
    width: calc(100% - 100px);
    margin-top: 90px;
  }

  .portfolio__title {
    color: #A0A0A0;
  }

  .portfolio__links-element {
    border-bottom: 1px solid #E6E6E6;

  }

  .portfolio__link {
    font-size: 28px;
    line-height: 1.786;
  }

  .portfolio__link-img {
    width: 15px;
    height: 15px;
  }
}

@media screen and (max-width: 425px) {
  .portfolio {
    width: calc(100% - 28px);
    margin-top: 69px;
  }

  .portfolio__title {
    font-size: 14px;
    line-height: 1.429;
    color: #A0A0A0;
  }

  .portfolio__links {
    margin-top: 41px;
  }

  .portfolio__links-element {
    border-bottom: 1px solid #E6E6E6;
    padding: 21px 0 18px;
  }

  .portfolio__links-element:first-of-type {
    padding: 0 0 18px;
  }

  .portfolio__links-element:last-of-type {
    border-bottom: none;
    padding: 21px 0 0;
  }


  .portfolio__link {
    font-size: 18px;
    line-height: 1.555;
  }

  .portfolio__link-img {
    width: 10px;
    height: 10px;
    margin: -4px 5px 0 0;
  }
}