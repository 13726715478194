.burger {
  visibility: hidden;
  opacity: 0;
  transition: all .3s linear;
  z-index: 1;
}

.burger_opened {
  visibility: visible;
  opacity: 1;
}

.burger__background {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .3)
}

.burger__container {
  max-width: 520px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: end;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #202020;
}

.burger__close-button {
  background: url(../../images/burger__close-btn.svg) no-repeat 50% 50%;
  border: none;
  margin: 27px 27px 0 0;
  padding: 11px;
  cursor: pointer;
  opacity: 1;
  transition: all .3s linear;
}

.burger__close-button:hover {
  opacity: .7;
}

.burger__nav {
  width: 100%;
  height: 100%;
  margin: 110px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
}

.burger__link {
  font-family: "Inter", Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.22;
  color: #fff;
  text-decoration: none;
  opacity: 1;
  transition: all .3s linear;
}

.burger__link:hover {
  opacity: .7;
}


.burger__link_active {
  font-family: "Inter", Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.22;
  color: #fff;
  text-decoration: none;
  padding-bottom: 4px;
  margin-bottom: -6px;
  border-bottom: 2px solid #fff;
  opacity: 1;
  transition: all .3s linear;
}

.burger__link_active:hover {
  opacity: .7;
}

.burger__profile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px 0 90px;
}

.burger__profile-link {
  background: url(../../images/navigation__profile.svg) no-repeat 50% 50%;
  border: none;
  width: 100px;
  height: 32px;
  opacity: 1;
  transition: all .3s linear;
}

.burger__profile-link:hover {
  opacity: .7;
}

@media screen and (min-width: 769px){
  .burger {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  .burger__close-button {
    margin: 18px 18px 0 0;
  }

  .burger__nav {
    margin-top: 104px;
    gap: 24px;
  }

  .burger__link_active {
    padding-bottom: 7px;
    margin-bottom: -9px;
  }

  .burger__profile {
    padding: 24px 0 46px;
  }
}