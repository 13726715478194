.page-not-found {
  width: 100%;
  padding: 246px 0 60px;
  background-color: #202020;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.page-not-found__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page-not-found__title {
  margin: 0;
  font-family: 'Inter', Arial, sans-serif;
  font-size: 140px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.21;
  color: #fff;
}

.page-not-found__text {
  margin: 5px 0 0;
  font-family: 'Inter', Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.18;
  color: #fff;
}

.page-not-found__link {
  margin-top: 184px;
  font-family: 'Inter', Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.21;
  color: #4285F4;
  text-decoration: none;
  opacity: 1;
  transition: all .3s linear;
}

.page-not-found__link:hover {
  opacity: .6;
}

@media screen and (min-width: 426px) and (max-width: 768px) {
  .page-not-found {
    padding: 408px 0 222px;
  }
}

@media screen and (max-width: 425px) {
  .page-not-found {
    padding: 329px 0 30px;
  }

  .page-not-found__title {
    font-size: 80px;
  }

  .page-not-found__text {
    margin-top: 10px;
    font-size: 12px;
    line-height: 1.25;
  }

  .page-not-found__link {
    margin-top: 284px;
    font-size: 12px;
    line-height: 1.25;
  }
}