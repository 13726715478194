.search {
  width: calc(100% - 140px);
  padding: 70px 0 69px;
  border-bottom: 1px solid #424242;
  background-color: #202020;
  display: flex;
  margin: 0 auto 10px;
}

.search__container {
  display: flex;
  align-items: center;
  background-color: #2F2F2F;
  width: 100%;
  height: 72px;
  border-radius: 9px;
  position: relative;
}

.search__form {
  display: flex;
  height: 40px;
  align-items: center;
  width: 100%;
  gap: 16px;
  border-right: 1px solid #424242;
}

.search__label {
  background: url('../../images/search__label.svg') no-repeat 50% 50%;
  display: flex;
  padding: 11px;
  margin-left: 25px;
}

.search__input {
  padding: 0;
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  font-family: 'Inter', Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.11;
  color: #fff;
}

.search__input:focus {
  border-bottom: 1px solid #4285F4;
}

.search__button-submit {
  background: url('../../images/search_btn.svg') no-repeat 50% 50%;
  padding: 17px;
  background-color: #4285F4;
  border-radius: 50%;
  border: none;
  margin-right: 30px;
  cursor: pointer;
  opacity: 1;
  transition: all .3s linear;
}

.search__button-submit:hover {
  opacity: .8;
}

.search__button-submit:disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.4;
}

.search__input-error {
  display: none;
  position: absolute;
  top: 80px;
  font-family: "Inter", Arial, sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.21;
  margin: 0;
  padding: 0;
  min-height: 12px;
  color: #EE3465;
}

.search__input-error_active {
  display: block;
}

@media screen and (min-width: 426px) and (max-width: 768px) {
  .search {
    width: calc(100% - 60px);
    padding: 80px 0 109px;
  }

  .search__form {
    border-right: 1px solid #505050;
  }

  .search__button-submit {
    margin-right: 19px;
  }
}

@media screen and (max-width: 425px) {
  .search {
    width: calc(100% - 20px);
    padding: 80px 0 0;
    border: none;
  }

  .search__container {
    flex-direction: column;
    background: transparent;
    height: 182px;
  }

  .search__form {
    height: 72px;
    border-right: none;
    background-color: #2F2F2F;
    border-radius: 9px;
  }

  .search__label {
    display: none;
  }

  .search__input {
    font-size: 14px;
    line-height: 1.43;
    margin-left: 19px;
  }

  .search__button-submit {
    margin-right: 19px;
  }
}