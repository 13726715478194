.header {
  background-color: #202020;
  width: 100%;
  height: 74px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__logo {
  background: url('../../images/header__logo.svg') no-repeat 50% 50%;
  width: 38px;
  height: 38px;
  opacity: 1;
  transition: all .3s linear;
}

.header__logo:hover {
  opacity: .7;
}
